import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  constructor(private router: Router) { }

  userName = "";
  email = ""
  
  ngOnInit(): void {
     this.userName = localStorage.getItem('name')
    this.email = localStorage.getItem('email')
    
  }
  logOut(){
    localStorage.clear();
    this.router.navigate(['/login'])
    
  }

}
